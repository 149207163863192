const Input = ({ name, placeHolder, defaultValue, error, icon, register }) => {
	return (
		<div className="field">
			<div className="control has-icons-left">
				<input
					className={`input is-medium ${error && 'is-danger'}`}
					name={name}
					defaultValue={defaultValue}
					type="text"
					placeholder={placeHolder}
					{...register(name)}
				/>
				<span className="icon is-small is-left">
					<i className={`fas ${icon}`}></i>
				</span>
			</div>
			{error && <p className="help is-danger">{error}</p>}
		</div>
	);
};

export default Input;

