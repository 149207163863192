import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useVerifyCodeMutation } from '../../../store';
import Input from '../../form/Input';
import useAppState from '../../../hooks/useAppState';
import Counter from '../../Counter';
import { useTranslation } from 'react-i18next';

const VerifyCode = ({ setStepVerifyCode, email }) => {
	//
	const [verifyCode, results] = useVerifyCodeMutation();
	const [errorMessage, setErrorMessage] = useState();
	const { setEmail, setEmailVerified, nextStep } = useAppState();
	const { t } = useTranslation();

	const {
		register,
		formState: { errors },
		handleSubmit
	} = useForm({
		mode: 'onSubmit',
		resolver: joiResolver(
			Joi.object({
				code: Joi.string()
					.required()
					.messages({
						'string.empty': t('Please enter code')
					})
			})
		)
	});

	const onSubmit = async (data, event) => {
		verifyCode({ email, ...data });
	};

	useEffect(() => {
		//
		if (results.isSuccess) {
			setEmailVerified(true);
			setEmail(email);
			nextStep();
		}
		if (results.error) {
			setErrorMessage(results.error.status === 401 ? t('Code is invalid, click "back" to try again') : t('GENERAL_ERROR'));
		}
		// eslint-disable-next-line
	}, [results]);

	return (
		<div className="extra-thin-page has-text-centered account-existing">
			<h1>{t('Enter Code')}</h1>
			<div className="subtitle">{t('Please enter the code we just emailed you')}</div>
			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<fieldset disabled={results.isLoading && 'disabled'}>
					<Input name="code" placeHolder="Code" defaultValue="" error={errors.code?.message} icon="fa-lock" register={register} />

					{errorMessage && (
						<article className="message is-danger">
							<div className="message-body">{errorMessage}</div>
						</article>
					)}

					<div className="buttons is-flex is-justify-content-center">
						<button
							type="button"
							className={`button is-medium is-light`}
							onClick={() => {
								setStepVerifyCode(false);
							}}
						>
							{t('Back')}
						</button>
						{` `}
						<button type="submit" className={`button is-medium is-warning ${results.isLoading ? 'is-loading' : ''}`}>
							{t('Verify Code')} <Counter minutes={5} handleFinish={() => setStepVerifyCode(false)} />
						</button>
					</div>
				</fieldset>
			</form>
		</div>
	);
};

export default VerifyCode;
