import { useSelector } from 'react-redux';
import useAppState from '../../hooks/useAppState';
import { useEffect, useState } from 'react';
import { useGetConfigQuery } from '../../store';
import { useTranslation } from 'react-i18next';

function PlanPage() {
	const { data } = useGetConfigQuery();
	const app = useSelector(state => state.app);
	const { setDuration, setPlan, setInsurance, nextStep } = useAppState();
	const [inUpsales, setInUpsales] = useState(false);
	const { t } = useTranslation();

	const durations = ['monthly', 'quarterly', 'yearly'];

	const renderedDurations = durations.map(duration => {
		return (
			<button
				key={duration}
				className={`button is-medium ${app.duration === duration ? 'selected' : ''}`}
				onClick={() => setDuration(duration)}
			>
				{duration}
			</button>
		);
	});

	const getPlans = async function () {};

	useEffect(() => {
		getPlans();
	}, []);

	const renderedPlans = data.plans.map((plan, index) => {
		const _features = plan.features.map((feature, index) => {
			return (
				<div className="icon-text" key={index}>
					<span className="icon">
						<i className="fas fa-home"></i>
					</span>
					<span className="text" dangerouslySetInnerHTML={{ __html: feature }} />
				</div>
			);
		});

		return (
			<div className="column" key={index}>
				<div className="pricing-plan">
					<div className="plan-head">
						<span className="name">{plan.name}</span>
						<span className="motivator">{plan.motivator}</span>
					</div>
					<div className="plan-content">
						<div className="price">
							${plan[app.duration].price}/{plan[app.duration].priceDuration}
						</div>
						<div className="duration">Billed {plan[app.duration].priceDurationCycle}</div>
						<div className="button-wrapper">
							<button
								className="button is-medium is-warning"
								onClick={() => {
									setPlan(plan.id);
									setInUpsales(true);
								}}
							>
								{plan.callToAction}
							</button>
						</div>
						<div className="list">{_features}</div>
					</div>
				</div>
			</div>
		);
	});

	const pagePlans = (
		<div id="plan">
			<div className="page-title">
				<h1>{t('Select Your Plan')}</h1>
				<div className="subtitle">{t('Choose your plan below')}</div>
			</div>
			<div className="buttons is-flex is-justify-content-center">
				<div className="buttons-wrapper">{renderedDurations}</div>
			</div>

			<div className="pricing-table">
				<div className="columns">{renderedPlans}</div>
			</div>
		</div>
	);

	const pageInsurance = (
		<div id="insurance">
			<div className="page-title">
				<h1>Insurance</h1>
				<div className="subtitle">
					{t('Would you like to add insurce for just {{price}} per unit?', {
						price: `$${data.insurance[app.duration].price}/${data.insurance[app.duration].billingCycle}`
					})}
				</div>
			</div>
			<div className="buttons is-flex is-justify-content-center">
				<button
					type="button"
					className={`button is-medium is-light`}
					onClick={() => {
						setInsurance(false);
						nextStep();
					}}
				>
					{t('No, thank you')}
				</button>
				{` `}
				<button
					type="submit"
					className={`button is-medium is-warning`}
					onClick={() => {
						setInsurance(true);
						nextStep();
					}}
				>
					{t('Yes, I play on the safe side!')}
				</button>
			</div>
		</div>
	);

	if (inUpsales) {
		return pageInsurance;
	} else {
		return pagePlans;
	}
}

export default PlanPage;
