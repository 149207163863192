import { useEffect, useState } from 'react';
import useModalContext from '../../hooks/useModalContext';
import { useGetConfigQuery } from '../../store';
import Image from '../Image';
import Modal from './Modal';
import ProgressBar from './ProgressBar';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAppState from '../../hooks/useAppState';

const Layout = ({ children }) => {
	//
	const [displayHeader, setDisplayHeader] = useState(true);
	const { modal } = useModalContext();
	const { error, isLoading } = useGetConfigQuery();
	const app = useSelector(state => state.app);
	const { setStep } = useAppState();
	const { t } = useTranslation();

	let content = children;

	if (isLoading) {
		content = (
			<progress className="progress is-medium is-info" max="100">
				60%
			</progress>
		);
	} else if (error) {
		content = <div className="notification is-danger is-light">{t('GENERAL_ERROR')}</div>;
	}

	useEffect(() => {
		if (new URLSearchParams(window.location.search).get('layout') === 'headless') {
			setDisplayHeader(false);
		}
	}, []);

	return (
		<>
			{modal && <Modal />}
			{displayHeader && (
				<>
					<header>
						<div className="container">
							<nav className="level is-mobile">
								<div className="level-left">
									<div className="level-item">
										<Image
											src="logo.png"
											className="logo"
											onClick={() => {
												setStep(0);
											}}
										/>
									</div>
								</div>
								<div className="level-right">
									<p className="level-item">
										<strong>
											<a href={`tel:${t('PHONE')}`}>
												<i className="fa-solid fa-phone"></i> {t('PHONE')}
											</a>
										</strong>
									</p>
								</div>
							</nav>
						</div>
					</header>
					<div className="container">{app['step'] >= 0 && <ProgressBar />}</div>
				</>
			)}

			<div className="container main">{content}</div>
			<footer></footer>
		</>
	);
};

export default Layout;
