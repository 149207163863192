import { useSelector } from 'react-redux';
import useAppState from '../../hooks/useAppState';
import { useCheckoutMutation, useGetConfigQuery } from '../../store';
import Image from '../Image';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../app/utils/trackEvent';

function CheckoutPage() {
	const { data } = useGetConfigQuery();
	const [errorMessage, setErrorMessage] = useState();
	const [checkout, results] = useCheckoutMutation();
	const app = useSelector(state => state.app);
	const { changeAccountInfoFromCheckout } = useAppState();
	const [paymentUrl, setPaymentUrl] = useState('');
	const { t } = useTranslation();

	const plan = data.plans.find(item => item.id === app.plan);

	const devicesPrice = app.devices.length * plan[app.duration].price;
	const insurancePrice = app.insurance ? app.devices.length * data.insurance[app.duration].price : 0;
	const insuranceDisplayPrice = insurancePrice ? `$${insurancePrice.toFixed(2)}` : 'Not Included';
	const totalPrice = devicesPrice + insurancePrice;

	useEffect(() => {
		//
		if (results.isSuccess) {
			trackEvent({
				name: 'checkout_redirect_to_payment',
				params: {
					devicesPrice,
					insurancePrice,
					totalPrice
				}
			});

			if (results.data.type === data.checkoutTypes.iframe) {
				setPaymentUrl(results.data.url);
			} else if (results.data.type === data.checkoutTypes.redirect) {
				window.location.replace(results.data.url);
			} else {
				trackEvent({
					name: 'checkout_redirect_to_payment_failed',
					params: {
						devicesPrice,
						insurancePrice,
						totalPrice
					}
				});
				setErrorMessage(t('GENERAL_ERROR'));
			}
		}

		if (results.error) {
			trackEvent({
				name: 'checkout_redirect_to_payment_failed',
				params: {
					devicesPrice,
					insurancePrice,
					totalPrice
				}
			});
			setErrorMessage(t('GENERAL_ERROR'));
		}

		// eslint-disable-next-line
	}, [results]);

	const devices = app.devices.map((device, index) => {
		const deviceInfo = data.devices.find(item => item.id === device.id);

		return (
			<div className="column is-12" key={index}>
				<div className="device">
					<div className="columns is-align-items-center">
						<div className="column is-3">
							<Image src={deviceInfo.image} />
						</div>
						<div className="column">
							<div className="info">
								<span className="bold">{deviceInfo.name}</span>
								<br />
								<span className="bold">{t('IMEI')}:</span> {device.imei}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	});

	const summary = (
		<>
			<div className="page-title">
				<h1>{t('Checkout')}</h1>
			</div>

			<div className="columns">
				<div className="column is-7">
					<div className="columns">
						<div className="column is-4">
							<div className="checkout-title">{t('1 Your Details')}</div>
						</div>
						<div className="column is-4">
							<div className="account-info">
								{app.name_first} {app.name_last}
								<br />
								{app.phone}
								<br />
								{app.email}
								<br />
								<span
									className="account-info-change"
									onClick={() => {
										changeAccountInfoFromCheckout();
									}}
								>
									{t('Change')}
								</span>
							</div>
						</div>
					</div>

					<div className="checkout-title has-margin">{t('2 Review Items')}</div>
					<div className="columns is-multiline devices">{devices}</div>
				</div>
				<div className="column is-5">
					<div className="summary">
						<div className="checkout-title has-margin">{t('3 Order Summary')}</div>

						<div className="columns is-multiline sub-total">
							<div className="column is-8 has-text-weight-bold">
								{app['devices'].length}x {data.plans.find(plan => plan.id === app.plan).name} {t('plan')}
							</div>
							<div className="column is-4 has-text-right">${devicesPrice.toFixed(2)}</div>
						</div>
						<div className="columns is-multiline sub-total">
							<div className="column is-8 has-text-weight-bold">
								{app.insurance && `${app.devices.length}x`} {t('Insurance Pack')}
							</div>
							<div className="column is-4 has-text-right">{insuranceDisplayPrice}</div>
						</div>

						<div className="columns is-multiline total has-text-weight-bold">
							<div className="column is-6 ">{t('Order Total')}</div>
							<div className="column is-6 has-text-right">${totalPrice.toFixed(2)}</div>
						</div>

						<div className="columns is-multiline sub-total">
							<div className="column is-12 has-text-right">
								{t('Billed')} {plan[app.duration].priceDurationCycle}
							</div>
						</div>

						<button
							type="submit"
							className={`button is-large has-text-weight-bold is-warning ${results.isLoading ? 'is-loading' : ''}`}
							onClick={() => checkout(app)}
						>
							{t('Proceed to payment')}
						</button>

						{errorMessage && (
							<article className="message is-danger">
								<div className="message-body" style={{ whiteSpace: 'pre-line' }}>
									{errorMessage}
								</div>
							</article>
						)}
					</div>
				</div>
			</div>
		</>
	);

	const payment = <iframe height="1000" title="checkout" src={paymentUrl}></iframe>;

	return <div id="checkout">{paymentUrl ? payment : summary}</div>;
}

export default CheckoutPage;
