import { useTranslation } from 'react-i18next';
import Image from '../Image';

const PaymentSuccessPage = () => {
	const { t } = useTranslation();
	return (
		<div className="extra-thin-page has-text-centered payment-result">
			<Image className="indicator" src="checkmark.png" />
			<h1>{t('Payment Successful')}</h1>
			<div className="subtitle">We will email you shortly with further instructions</div>
		</div>
	);
};

export default PaymentSuccessPage;
