import Image from './Image';

const Imei = () => {
	return (
		<>
			<div className="columns">
				<div className="column is-2">
					<Image src="imei-example.png" />
				</div>
				<div className="column is-10">
					<p className="title is-4">Name of device</p>
					<p className="subtitle is-5">
						Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
						standard.
					</p>
				</div>
			</div>

			<div className="columns">
				<div className="column is-2">
					<Image src="imei-example.png" />
				</div>
				<div className="column is-10">
					<p className="title is-4">Name of device</p>
					<p className="subtitle is-5">
						Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
						standard.
					</p>
				</div>
			</div>

			<div className="columns">
				<div className="column is-2">
					<Image src="imei-example.png" />
				</div>
				<div className="column is-10">
					<p className="title is-4">Name of device</p>
					<p className="subtitle is-5">
						Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
						standard.
					</p>
				</div>
			</div>

			
		</>
	);
};

export default Imei;
