import { useTranslation } from 'react-i18next';
import Image from '../Image';

const PaymentSuccessPage = () => {
	const { t } = useTranslation();
	return (
		<div className="extra-thin-page has-text-centered payment-result">
			<Image className="indicator" src="xmark.png" />
			<h1>{t('Payment Unsuccessful')}</h1>
			<div className="subtitle">
				Please contact us:
				<br />
				{t('Email')}: <a href={`mailto:${t('EMAIL')}`}>{t('EMAIL')}</a>
				<br />
				{t('Phone')}: <a href={`tel:${t('PHONE')}`}>{t('PHONE')}</a>
			</div>
		</div>
	);
};

export default PaymentSuccessPage;
