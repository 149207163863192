import useAppState from '../../../hooks/useAppState';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const AccountVerified = () => {
	//
	const app = useSelector(state => state.app);
	const { nextStep, setStep, setEmailVerified } = useAppState();
	const { t } = useTranslation();

	return (
		<div className="extra-thin-page has-text-centered account-existing">
			<h1>{t('Existing Account')}</h1>
			<div className="subtitle">{app.email}</div>
			<div className="buttons is-flex is-justify-content-center">
				<button
					type="button"
					className={`button is-medium is-light`}
					onClick={() => {
						setStep(0);
						setEmailVerified(false);
					}}
				>
					{t('Start Over')}
				</button>
				{` `}
				<button
					type="submit"
					className="button is-medium is-warning"
					onClick={() => {
						nextStep();
					}}
				>
					{t('Continue')}
				</button>
			</div>
		</div>
	);
};

export default AccountVerified;
