import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useVerifyEmailMutation } from '../../../store';
import Input from '../../form/Input';
import useAppState from '../../../hooks/useAppState';
import { useTranslation } from 'react-i18next';

const VerifyEmail = ({ setStepVerifyCode, setEmail }) => {
	//
	const [verifyEmail, results] = useVerifyEmailMutation();
	const [errorMessage, setErrorMessage] = useState();
	const { setStep, setIsNew } = useAppState();
	const { t } = useTranslation();

	const {
		register,
		formState: { errors },
		handleSubmit,
		getValues
	} = useForm({
		mode: 'onSubmit',
		resolver: joiResolver(
			Joi.object({
				email: Joi.string()
					.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)
					.message(t('Invalid email'))
					.messages({
						'string.empty': t('Please enter your email')
					})
			})
		)
	});

	const onSubmit = async (data, event) => {
		verifyEmail(data);
	};

	useEffect(() => {
		//
		if (results.isSuccess) {
			setEmail(getValues('email'));
			setStepVerifyCode(true);
		}
		if (results.error) {
			setErrorMessage(
				results.error.status === 404 ? (
					<>
						{t('It seems that there is no account associated with this email address, to create an account: ')}
						<span
							className="link"
							onClick={e => {
								e.preventDefault();
								setIsNew(true);
							}}
						>
							{t('click here')}
						</span>
					</>
				) : (
					t('GENERAL_ERROR')
				)
			);
		}
		// eslint-disable-next-line
	}, [results]);

	return (
		<div className="extra-thin-page has-text-centered account-existing">
			<h1>{t('Verify Your Account')}</h1>
			<div className="subtitle">
				{t('Please enter your email address')}
				<br />
				{t('We will email you a verification code')}
			</div>
			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<fieldset disabled={results.isLoading && 'disabled'}>
					<Input
						name="email"
						placeHolder={t('Email')}
						defaultValue=""
						error={errors.email?.message}
						icon="fa-envelope"
						register={register}
					/>

					{errorMessage && (
						<article className="message is-danger">
							<div className="message-body">{errorMessage}</div>
						</article>
					)}

					<div className="buttons is-flex is-justify-content-center">
						<button
							type="button"
							className={`button is-medium is-light`}
							onClick={() => {
								setStep(0);
							}}
						>
							{t('Back')}
						</button>
						{` `}
						<button type="submit" className={`button is-medium is-warning ${results.isLoading ? 'is-loading' : ''}`}>
							{t('Verify Account')}
						</button>
					</div>
				</fieldset>
			</form>
		</div>
	);
};

export default VerifyEmail;
