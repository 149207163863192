import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { useCheckEmailMutation } from '../../../store';
import { useSelector } from 'react-redux';
import Input from '../../form/Input';
import useAppState from '../../../hooks/useAppState';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumber } from 'libphonenumber-js';
import { _defaultCountry } from '../../../app/config';
import { useTranslation } from 'react-i18next';

const NewAccount = () => {
	const [activateApi, results] = useCheckEmailMutation();
	const [errorMessage, setErrorMessage] = useState();
	const { setAccount, nextStep, setIsNew, setStep } = useAppState();
	const app = useSelector(state => state.app);
	const [country, setCountry] = useState(app['country'] || _defaultCountry);
	const { t } = useTranslation();

	const handleFlagChange = (event, country) => {
		if (country?.countryCode) {
			setCountry(country.countryCode);
		}
	};

	const phoneNumberValidator = (value, helpers) => {
		const phoneNumber = parsePhoneNumber(value, country.toUpperCase());

		if (!phoneNumber.isValid()) {
			return helpers.error('any.invalid');
		}

		return value;
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
		getValues,
		control
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			phone: app['phone']
		},
		resolver: joiResolver(
			Joi.object({
				name_first: Joi.string()
					.required()
					.messages({
						'string.empty': t('Please enter your first name')
					}),
				name_last: Joi.string()
					.required()
					.messages({
						'string.empty': t('Please enter your last name')
					}),
				phone: Joi.string()
					.custom(phoneNumberValidator)
					.message('Invalid phone number')
					.messages({
						'string.empty': t('Please enter your phone number')
					}),
				email: Joi.string()
					.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)
					.message(t('Invalid email'))
					.messages({
						'string.empty': t('Please enter your email')
					}),
				email_repeat: Joi.string()
					.valid(Joi.ref('email'))
					.messages({
						'string.empty': t('Please enter your email again'),
						'any.only': t('Emails do not match')
					}),
				company: Joi.string().allow('', null).optional()
			})
		)
	});

	const onSubmit = async (data, event) => {
		activateApi(data);
	};

	useEffect(() => {
		//
		if (results.isSuccess) {
			setAccount({ country, ...getValues() });
			nextStep();
		}

		if (results.error) {
			setErrorMessage(
				results.error.status === 409 ? (
					<>
						{t('Email already exist, to login: ')}
						<span
							className="link"
							onClick={e => {
								e.preventDefault();
								setIsNew(false);
							}}
						>
							{t('click here')}
						</span>
					</>
				) : (
					t('GENERAL_ERROR')
				)
			);
		}

		// eslint-disable-next-line
	}, [results]);

	return (
		<div className="thin-page">
			<div className="page-title">
				<h1>{t('Time to activate your device!')}</h1>
			</div>
			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<fieldset disabled={results.isLoading && 'disabled'}>
					<Input
						name="name_first"
						placeHolder={t('First Name')}
						defaultValue={app['name_first']}
						error={errors.name_first?.message}
						icon="fa-user"
						register={register}
					/>

					<Input
						name="name_last"
						placeHolder={t('Last Name')}
						defaultValue={app['name_last']}
						error={errors.name_last?.message}
						icon="fa-user"
						register={register}
					/>

					<div className="field">
						<div className="control has-icons-left">
							<Controller
								name="phone"
								control={control}
								render={({ field: { onChange, onBlur, name, value, ref } }) => (
									<PhoneInput
										inputProps={{
											name,
											required: true,
											className: `input is-medium ${errors.phone?.message && 'is-danger'}`
										}}
										country={country.toLowerCase()}
										value={value}
										onChange={(event, data) => {
											onChange(event);
											handleFlagChange(event, data);
										}}
										enableSearch={true}
										className=""
									/>
								)}
							/>
							{errors.phone?.message && <p className="help is-danger">{errors.phone?.message}</p>}
						</div>
					</div>

					<Input
						name="email"
						placeHolder={t('Email')}
						defaultValue={app['email']}
						error={errors.email?.message}
						icon="fa-envelope"
						register={register}
					/>

					<Input
						name="email_repeat"
						placeHolder={t('Email Again')}
						defaultValue={app['email_repeat']}
						error={errors.email_repeat?.message}
						icon="fa-envelope"
						register={register}
					/>
					<Input
						name="company"
						placeHolder={t('Company (If applicable)')}
						defaultValue={app['company']}
						error={errors.company?.message}
						icon="fa-city"
						register={register}
					/>

					{errorMessage && (
						<article className="message is-danger">
							<div className="message-body">{errorMessage}</div>
						</article>
					)}

					<div className="buttons is-flex is-justify-content-center">
						<button
							type="button"
							className={`button is-medium is-light`}
							onClick={() => {
								setStep(0);
							}}
						>
							{t('Back')}
						</button>
						{` `}
						<button className={`button is-medium is-warning ${results.isLoading ? 'is-loading' : ''}`}>{t('Continue')}</button>
					</div>
				</fieldset>
			</form>
		</div>
	);
};

export default NewAccount;
