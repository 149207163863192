import { _steps } from '../../App';
import useAppState from '../../hooks/useAppState';
import { useSelector } from 'react-redux';

const ProgressBar = ({ children }) => {
	//
	const app = useSelector(state => state.app);
	const { setStep } = useAppState();

	const steps = _steps.map((step, index) => {
		//

		if (!step.inProgressBar) {
			return null;
		}

		return (
			<li
				key={index}
				className={`steps-segment 
					${app['step'] === index ? 'is-active' : ''} 
					${app['step'] > index ? 'is-clickable' : ''} 
				`}
				onClick={() => {
					if (app['step'] > index) {
						setStep(index);
					}
				}}
			>
				<span className="steps-marker"></span>
				<div className="steps-content">
					<p className="is-size-8">{step.name}</p>
				</div>
			</li>
		);
	});

	return <ul className="steps has-content-centered is-horizontal is-small">{steps}</ul>;
};

export default ProgressBar;
