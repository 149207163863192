export const eventTypes = {
	event: 'event',
	identify: 'identify'
};

export const trackEvent = async obj => {
	let { type, name, params } = obj;

	name = name ? name.toLowerCase() : undefined;

	//////////
	// Klavio
	//////////

	if (window.location.hostname.includes('localhost')) {
		//
		console.log('Event', name, params, type);
		//
	} else {
		//
		let data = ['track', name, params];

		if (type === eventTypes.identify) {
			data = ['identify', params];
		}

		window._learnq.push(data);
	}

	//////////
	// Analytics
	//////////
	if (type !== eventTypes.identify) {
		window.dataLayer.push({ event: name, ...params });
		// window.gtag('event', name, params);
	}
};
