import { useEffect, useState } from 'react';

function Counter({ handleFinish, minutes }) {
	//
	const initialTime = minutes * 60 - 10; // 10 seconds safety

	// State to keep track of time left
	const [timeLeft, setTimeLeft] = useState(initialTime);

	useEffect(() => {
		// Exit early when we reach 0
		if (timeLeft === 0) {
			handleFinish();
			return;
		}

		// Save intervalId to clear the interval when the
		// component unmounts
		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [timeLeft, handleFinish]);

	// Format the time left into minutes:seconds
	const formatTime = () => {
		const minutes = Math.floor(timeLeft / 60);
		const seconds = timeLeft % 60;
		return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	};

	return formatTime();
}

export default Counter;
