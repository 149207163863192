import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const appApi = createApi({
	reducerPath: 'activation',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BACKEND_URL + 'client/'
	}),
	endpoints(builder) {
		return {
			checkEmail: builder.mutation({
				query: body => {
					return {
						method: 'POST',
						url: `/check-email`,
						body
					};
				}
			}),
			checkImei: builder.mutation({
				query: body => {
					return {
						method: 'POST',
						url: `/check-imei`,
						body
					};
				}
			}),
			getConfig: builder.query({
				query: body => {
					return {
						method: 'GET',
						url: `/config`,
						body
					};
				}
			}),
			checkout: builder.mutation({
				query: body => {
					return {
						method: 'POST',
						url: `/checkout`,
						body
					};
				}
			}),
			verifyEmail: builder.mutation({
				query: body => {
					return {
						method: 'POST',
						url: `/verify-email`,
						body
					};
				}
			}),
			verifyCode: builder.mutation({
				query: body => {
					return {
						method: 'POST',
						url: `/verify-code`,
						body
					};
				}
			})
		};
	}
});

export const {
	useCheckoutMutation,
	useGetConfigQuery,
	useCheckEmailMutation,
	useCheckImeiMutation,
	useVerifyEmailMutation,
	useVerifyCodeMutation
} = appApi;
export { appApi };
