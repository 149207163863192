import useAppState from '../../hooks/useAppState';
import { useTranslation } from 'react-i18next';

const WelcomePage = () => {
	const { setIsNew, nextStep } = useAppState();
	const { t } = useTranslation();

	const handleClick = isNew => {
		setIsNew(isNew);
		nextStep();
	};

	return (
		<div id="welcome" className="thin-1page">
			<div className="page-title">
				<h1>{t('Time to activate your device!')}</h1>
				<div className="columns">
					<div className="column">
						<div className="title is-2">{t('New Customer')}</div>
						<div className="subtitle is-3">{t('Add devices to a new account')}</div>

						<button type="submit" className="button is-medium is-warning" onClick={() => handleClick(true)}>
							{t('Continue To Next Step')}
						</button>
					</div>
					<div className="column">
						<div className="title is-2">{t('Existing Customer')}</div>
						<div className="subtitle is-3">{t('Add devices to your existing account')}</div>
						<button type="submit" className="button is-medium is-warning" onClick={() => handleClick(false)}>
							{t('Continue To Next Step')}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WelcomePage;
