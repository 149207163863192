import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { appSlice } from './slices/appSlice';
import { appApi } from './apis/appApi';

const store = configureStore({
	reducer: {
		app: appSlice.reducer,
		[appApi.reducerPath]: appApi.reducer
	},
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware().concat([appApi.middleware]);
	}
});

setupListeners(store.dispatch);

export { store, appSlice };

export const { setInsurance, setPlan, setDuration, setDevices, setAccount, setStep, setIsNew, setEmailVerified, setEmail } =
	appSlice.actions;

export {
	useCheckoutMutation,
	useGetConfigQuery,
	useCheckEmailMutation,
	useCheckImeiMutation,
	useVerifyEmailMutation,
	useVerifyCodeMutation
} from './apis/appApi';
