import { _steps } from '../App';
import { eventTypes, trackEvent } from '../app/utils/trackEvent';
import {
	store,
	setInsurance,
	setPlan,
	setDuration,
	setAccount,
	setDevices,
	setStep as setStoreStep,
	setIsNew,
	setEmailVerified,
	setEmail,
	useGetConfigQuery
} from '../store';
import { useSelector } from 'react-redux';

const useAppState = () => {
	//
	const app = useSelector(state => state.app);
	const { data } = useGetConfigQuery();

	const updateStep = step => {
		sessionStorage.setItem('step', step);
		store.dispatch(setStoreStep({ step }));
	};

	return {
		setAccount: data => {
			//
			const { name_first, name_last, phone, email, email_repeat, country, company } = data;

			sessionStorage.setItem('name_first', name_first);
			sessionStorage.setItem('name_last', name_last);
			sessionStorage.setItem('phone', phone);
			sessionStorage.setItem('email', email);
			sessionStorage.setItem('email_repeat', email_repeat);
			sessionStorage.setItem('country', country);
			sessionStorage.setItem('company', company);

			store.dispatch(setAccount(data));

			trackEvent({
				type: eventTypes.identify,
				params: {
					$first_name: name_first,
					$last_name: name_last,
					$phone_number: phone,
					$email: email,
					$country: country
				}
			});
		},
		setDevices: data => {
			//
			sessionStorage.setItem('devices', JSON.stringify(data));
			store.dispatch(setDevices(data));
		},
		setDuration: duration => {
			//
			sessionStorage.setItem('duration', duration);
			store.dispatch(setDuration({ duration }));

			trackEvent({ name: `subscription_duration_${duration}` });
		},
		setPlan: plan => {
			//
			sessionStorage.setItem('plan', plan);
			store.dispatch(setPlan({ plan }));

			const planName = data.plans.find(inPlan => inPlan.id === plan).name;

			trackEvent({ name: `plan_select_${planName}` });
		},
		setInsurance: insurance => {
			//
			sessionStorage.setItem('insurance', insurance ? 1 : 0);
			store.dispatch(setInsurance({ insurance }));

			trackEvent({ name: `insurance_select_${insurance ? 'yes' : 'no'}` });
		},
		changeAccountInfoFromCheckout: step => {
			// Place holder for tracking events
			updateStep(0);

			trackEvent({ name: 'change_account_info_from_checkout' });
		},
		setIsNew: isNew => {
			//
			sessionStorage.setItem('is_new', isNew ? 1 : 0);
			store.dispatch(setIsNew({ isNew }));
		},
		setEmailVerified: emailVerified => {
			//
			sessionStorage.setItem('email_verified', emailVerified ? 1 : 0);
			store.dispatch(setEmailVerified({ emailVerified }));

			trackEvent({ name: `email_otp_verified_${emailVerified}` });
		},
		setEmail: email => {
			//
			sessionStorage.setItem('email', email);
			store.dispatch(setEmail({ email }));

			trackEvent({
				type: eventTypes.identify,
				params: {
					$email: email
				}
			});
		},
		setStep: step => {
			updateStep(step);
		},
		previousStep: () => {
			if (app['step'] === 0) {
				return;
			}

			const step = app['step'] - 1;
			updateStep(step);
		},
		nextStep: () => {
			if (!_steps[app.step + 1].inProgressBar) {
				return;
			}

			const step = app['step'] + 1;
			updateStep(step);
		}
	};
};

export default useAppState;
