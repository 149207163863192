import { useSelector } from 'react-redux';
import NewAccount from './Account/NewAccount';
import { useState } from 'react';
import VerifyCode from './Account/VerifyCode';
import VerifyEmail from './Account/VerifyEmail';
import AccountVerified from './Account/AccountVerified';

const AccountPage = () => {
	const app = useSelector(state => state.app);
	const [stepVerifyCode, setStepVerifyCode] = useState(false);
	const [email, setEmail] = useState('');

	if (app['is_new']) {
		return <NewAccount />;
	}

	if (app['email_verified']) {
		return <AccountVerified />;
	}

	if (stepVerifyCode) {
		return <VerifyCode setStepVerifyCode={setStepVerifyCode} email={email} />;
	}

	return <VerifyEmail setStepVerifyCode={setStepVerifyCode} setEmail={setEmail} />;
};

export default AccountPage;
