import { createSlice } from '@reduxjs/toolkit';
import { _defaultDuration } from '../../app/config';

const appSlice = createSlice({
	name: 'app',
	initialState: {
		step: sessionStorage.getItem('step') ? Number(sessionStorage.getItem('step')) : 0,
		name_first: sessionStorage.getItem('name_first') || null,
		name_last: sessionStorage.getItem('name_last') || null,
		phone: sessionStorage.getItem('phone') || null,
		email: sessionStorage.getItem('email') || null,
		email_repeat: sessionStorage.getItem('email_repeat') || null,
		country: sessionStorage.getItem('country') || null,
		company: sessionStorage.getItem('company') || null,
		devices: sessionStorage.getItem('devices') ? JSON.parse(sessionStorage.getItem('devices')) : null,
		duration: sessionStorage.getItem('duration') || _defaultDuration,
		plan: Number(sessionStorage.getItem('plan')) || null,
		insurance: sessionStorage.getItem('insurance') ? !!Number(sessionStorage.getItem('insurance')) : false,
		is_new: sessionStorage.getItem('is_new') ? !!Number(sessionStorage.getItem('is_new')) : true,
		email_verified: sessionStorage.getItem('email_verified') ? !!Number(sessionStorage.getItem('email_verified')) : false
	},
	reducers: {
		setAccount(state, data) {
			state['name_first'] = data.payload.name_first;
			state['name_last'] = data.payload.name_last;
			state['phone'] = data.payload.phone;
			state['email'] = data.payload.email;
			state['email_repeat'] = data.payload.email_repeat;
			state['country'] = data.payload.country;
			state['company'] = data.payload.company;
		},
		setDevices(state, data) {
			state['devices'] = data.payload;
		},
		setPlan(state, data) {
			state['plan'] = data.payload.plan;
		},
		setDuration(state, data) {
			state['duration'] = data.payload.duration;
		},
		setInsurance(state, data) {
			state['insurance'] = data.payload.insurance;
		},
		setIsNew(state, data) {
			state['is_new'] = data.payload.isNew;
		},
		setEmailVerified(state, data) {
			state['email_verified'] = data.payload.emailVerified;
		},
		setEmail(state, data) {
			state['email'] = data.payload.email;
		},
		setStep(state, data) {
			state['step'] = data.payload.step;
		}
	}
});

export { appSlice };
