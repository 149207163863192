import { useEffect } from 'react';
import './scss/style.scss';
import { useSelector } from 'react-redux';
import useAppState from './hooks/useAppState';
import PaymentSuccessPage from './components/pages/PaymentSuccess';
import PaymentUnsuccessfulPage from './components/pages/PaymentUnsuccessful';
import AccountPage from './components/pages/Account';
import CheckoutPage from './components/pages/Checkout';
import DevicePage from './components/pages/Device';
import PlanPage from './components/pages/Plan';
import WelcomePage from './components/pages/Welcome';
import { trackEvent } from './app/utils/trackEvent';

export const _steps = [
	{
		name: 'Welcome',
		inProgressBar: false,
		component: () => <WelcomePage />
	},
	{
		name: 'Account',
		inProgressBar: true,
		component: () => <AccountPage />
	},
	{
		name: 'Device',
		inProgressBar: true,
		component: () => <DevicePage />
	},
	{
		name: 'Plan',
		inProgressBar: true,
		component: () => <PlanPage />
	},
	{
		name: 'Checkout',
		inProgressBar: true,
		component: () => <CheckoutPage />
	},
	{
		name: 'Payment Success',
		inProgressBar: false,
		component: () => <PaymentSuccessPage />,
		urlAction: 'payment_success'
	},
	{
		name: 'Payment Unsuccessful',
		inProgressBar: false,
		component: () => <PaymentUnsuccessfulPage />,
		urlAction: 'payment_unsuccessful'
	}
];

function App() {
	//
	const app = useSelector(state => state.app);

	const { setStep } = useAppState();

	const ComponentToRender = _steps[app.step].component;

	useEffect(() => {
		//
		const findStep = _steps.findIndex(step => step.urlAction === new URLSearchParams(window.location.search).get('action'));

		if (findStep > 0) {
			setStep(findStep);
		}

		// Track page view
		trackEvent({ name: `page_view_${_steps[app.step].name}` });
		//
	}, [setStep, app.step]);

	return <ComponentToRender />;
}

export default App;
