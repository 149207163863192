import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import useModalContext from '../../hooks/useModalContext';
import Imei from '../Imei';
import { useCheckImeiMutation } from '../../store';
import useAppState from '../../hooks/useAppState';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../app/utils/trackEvent';

function DevicePage() {
	const [errorMessage, setErrorMessage] = useState();
	const [checkImei, results] = useCheckImeiMutation();
	const { nextStep, setDevices } = useAppState();
	const app = useSelector(state => state.app);
	const { t } = useTranslation();
	const { openModal } = useModalContext();

	const {
		register,
		control,
		handleSubmit,
		formState: { errors }
	} = useForm({
		mode: 'onChange'
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'devices'
	});

	useEffect(() => {
		if (app['devices']) {
			app['devices'].forEach(item => {
				append({ imei: item.imei });
			});
			//
		} else {
			//
			append({ imei: '' });
		}

		// eslint-disable-next-line
	}, [append]);

	const onSubmit = async (data, event) => {
		checkImei(data);
	};

	useEffect(() => {
		//
		if (results.isSuccess) {
			setDevices(results.data);
			nextStep();
		}

		if (results.error) {
			setErrorMessage(results.error.status === 422 ? `${results.error.data.message}` : t('GENERAL_ERROR'));
		}

		// eslint-disable-next-line
	}, [results]);

	const handleAddDevice = () => {
		append({ name: '' });
		trackEvent({ name: 'devices_add_additional_device' });
	};

	const handleRemoveDevice = index => {
		remove(index);
		trackEvent({ name: 'devices_remove_additional_device' });
	};

	const _fields = fields.map((item, index) => {
		//
		const error = errors.devices && errors.devices[index]?.imei && errors.devices[index].imei.message;

		return (
			<div className="field" key={`imei${index}`}>
				<div className="columns">
					<div className="column is-11">
						<input
							{...register(`devices[${index}].imei`, {
								required: true,
								pattern: {
									value: /^[0-9]*$/,
									message: t('Please enter numbers only')
								}
							})}
							placeholder={t('IMEI')}
							className={`input is-medium ${error && 'is-danger'}`}
							defaultValue={item.imei}
							tabIndex={index + 2}
							autoComplete="off"
						/>
					</div>
					{index !== 0 && (
						<div className="column is-flex is-align-items-center is-justify-content-center">
							<button
								type="button"
								className="button is-text icon is-right"
								tabIndex="-1"
								onClick={() => {
									handleRemoveDevice(index);
								}}
							>
								<i className="fas fa-trash"></i>
							</button>
						</div>
					)}
				</div>

				{error && <p className="help is-danger">{error}</p>}
			</div>
		);
	});

	return (
		<div id="imei" className="thin-page">
			<div className="page-title">
				<h1>{t("Please enter the IMEI for each device you'd like to activate")}</h1>
				<div className="find" onClick={() => openModal(<Imei />, t('Find Your IMEI'))}>
					{t('Need help finding your IMEI?')} <span>{t('click Here')}</span>
				</div>
			</div>
			<form className="form imei" onSubmit={handleSubmit(onSubmit)}>
				<fieldset disabled={results.isLoading && 'disabled'}>
					{_fields}

					{errorMessage && (
						<article className="message is-danger">
							<div className="message-body" style={{ whiteSpace: 'pre-line' }}>
								{errorMessage}
							</div>
						</article>
					)}

					<div className="buttons is-flex is-justify-content-center">
						<button
							type="button"
							className={`button is-medium is-light`}
							onClick={() => {
								handleAddDevice();
							}}
						>
							{t('Add Another Device')}
						</button>
						{` `}
						<button type="submit" className={`button is-medium is-warning ${results.isLoading ? 'is-loading' : ''}`}>
							{t('Continue To Next Step')}
						</button>
					</div>
				</fieldset>
			</form>
		</div>
	);
}

export default DevicePage;
